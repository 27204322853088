var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.phone_brands,"search":_vm.search,"server-items-length":_vm.total,"options":_vm.serverOptions,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:options":function($event){_vm.serverOptions=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.close}},'v-btn',attrs,false),on),[_vm._v(" เพิ่มยี่ห้อโทรศัพท์ ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"ชื่อยี่ห้อโทรศัพท์"},model:{value:(_vm.editedItem.phone_brand_name),callback:function ($$v) {_vm.$set(_vm.editedItem, "phone_brand_name", $$v)},expression:"editedItem.phone_brand_name"}}),_vm._l((_vm.v$.editedItem.phone_brand_name.$silentErrors),function(err,index){return (_vm.v$.editedItem.phone_brand_name.$error)?_c('small',{staticClass:"validate-err"},[_vm._v(_vm._s(index > 0 ? ', ' : '')+" "+_vm._s(err.$message))]):_vm._e()})],2)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 mb-5"},[_vm._v(" Are you sure you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":_vm.closeDelete}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(((_vm.serverOptions.page - 1) * _vm.serverOptions.itemsPerPage) + index + 1)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":"Y"},on:{"click":function($event){return _vm.UpdateStatus(item.phone_brand_id)}},model:{value:(item.phone_brand_status),callback:function ($$v) {_vm.$set(item, "phone_brand_status", $$v)},expression:"item.phone_brand_status"}})]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }