<template>
  <v-data-table
    :headers="headers"
    :items="phone_brands"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <div class="d-flex justify-space-between"> -->
            <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="close"
            >
              เพิ่มยี่ห้อโทรศัพท์
            </v-btn>
            <!-- </div> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.phone_brand_name"
                      label="ชื่อยี่ห้อโทรศัพท์"
                    ></v-text-field>
                    <small
                      v-for="(err,index) in v$.editedItem.phone_brand_name.$silentErrors"
                      v-if="v$.editedItem.phone_brand_name.$error"
                      class="validate-err"
                    >{{ index > 0 ? ', ' : '' }}
                      {{ err.$message }}</small>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>

    <template v-slot:item.status="{ item }">
      <v-checkbox
        v-model="item.phone_brand_status"
        value="Y"
        @click="UpdateStatus(item.phone_brand_id)"
      ></v-checkbox>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
import DatePicker from 'vue2-datepicker'

// import config from '../../../config'
import Swal from 'sweetalert2'
import { useVuelidate } from '@vuelidate/core'
import { email, minLength, required } from '@vuelidate/validators'
import instance_phone_brand from '../../../services/phoneBrand'
import instance from '../../../axios.service'

export default {
  components: { DatePicker },
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    serverOptions: {
      page: 1,
      itemsPerPage: 10,
    },
    total: 0,
    phone_brands: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false,
      },
      { text: 'ยี่ห้อโทรศัพท์)', value: 'phone_brand_name' },
      { text: 'สถานะ (เปิด/ปิด)', value: 'status' },
      { text: 'แก้ไขข้อมูล', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      phone_brand_id: '',
      phone_brand_name: '',
      phone_brand_status: 'N',
    },
    defaultItem: {
      phone_brand_id: '',
      phone_brand_name: '',
      phone_brand_status: 'N',
    },
  }),

  validations() {
    return {
      editedItem: {
        phone_brand_name: { required },
      },
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม ยี่ห้อโทรศัพท์' : 'แก้ไข ยี่ห้อโทรศัพท์'
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getPhoneBrand()
      },
    },
    search: {
      handler() {
        this.getPhoneBrand()
      },
    },

    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getPhoneBrand()
  },

  methods: {
    editItem(item) {
      this.v$.$reset()
      this.editedIndex = this.phone_brands.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.phone_brands.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },
    UpdateStatus(id) {
      this.$store.state.isLoading = true
      instance_phone_brand.updateStatus(id).then(res => {
        Swal.fire({
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500,
        })

        this.$store.state.isLoading = false
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false
      })
    },
    deleteItemConfirm() {
      this.$store.state.isLoading = true
      instance_phone_brand.destroy(this.editedItem).then(res => {
        setTimeout(() => {
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          })
        }, 150)
        this.getPhoneBrand()
        this.closeDelete()
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false
      })
    },

    close() {
      this.v$.$reset()
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      this.$store.state.isLoading = true
      if (this.editedIndex > -1) {
        instance_phone_brand.update(this.editedItem)
          .then(res => {
            setTimeout(() => {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              })
            }, 150)
            this.getPhoneBrand()
            this.close()
          }).catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด !!',
              text: err.message,
            })
            this.$store.state.isLoading = false
          })
      } else {
        instance_phone_brand.store(this.editedItem)
          .then(res => {
            setTimeout(() => {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              })
            }, 150)
            this.getPhoneBrand()
            this.close()
          }).catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด !!',
              text: err.message,
            })
            this.$store.state.isLoading = false
          })
      }
    },
    getPhoneBrand() {
      this.$store.state.isLoading = true
      const data = {
        search: this.search,
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        status: '',
        sortBy: this.sortBy ?? '',
        sortDesc: this.sortDesc ?? '',
      }

      instance_phone_brand.get(data).then(res => {
        this.phone_brands = res.data.data.data
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
